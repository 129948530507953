import axios from "axios"

const HOST = "https://cms.talent-indonesia.id/wp-json/wp/v2"
const headers = { "Content-Type": "application/json" }

const wpFetcher = axios.create({ baseURL: HOST, headers })
export const fetcherCms = wpFetcher

const groupBy = (jadwal, key, sub) => {
  if (jadwal?.length > 0) {
    return jadwal?.reduce((rv, x) => {
      let industry = x[key][sub]
      rv[industry] = rv[industry] || []
      rv[industry].push(x)
      return rv
    }, {})
  }
}

export const getContentForHomepage = async (preview) => {
  let formatted = await Promise.all([
    wpFetcher({
      method: "GET",
      url: "/custom-page/106",
    })
      .then((res) => res?.data?.acf)
      .catch((err) => {
        console.log({ FAILED_FETCH_HOMEPAGE: err })
        return null
      }),

    wpFetcher({
      method: "GET",
      url: "/benefit",
    })
      .then((res) => res?.data)
      .then((data) => {
        return data
          ?.sort((a, b) => a?.id - b?.id)
          .map((d) => ({
            title: d?.title?.rendered,
            body: d?.acf?.body_benefit,
          }))
      })
      .catch((err) => {
        console.log({ FAILED_FETCH_BENEFIT: err })
        return null
      }),
  ]).then((res) => ({
    ...res[0],
    benefits: res[1],
  }))

  // console.log(formatted)

  return formatted
}

export const getContentForTentangKami = async () => {
  let formatted = await Promise.all([
    wpFetcher({
      method: "GET",
      url: "/custom-page/139",
    })
      .then((res) => res?.data)
      .then((data) => ({
        title: data?.acf?.title_about,
        body: data?.acf?.body_about,
        cover: data?.acf?.cover_about,
        company: {
          title: data?.acf?.company_title,
          name: data?.acf?.company_name,
          director: data?.acf?.company_director,
          commissioner: data?.acf?.company_commissioner,
          established: data?.acf?.company_established,
          recognized: data?.acf?.company_recognized,
          address: data?.acf?.company_address,
          services: data?.acf?.company_services,
          sectors: data?.acf?.company_sectors,
        },
      }))
      .catch((err) => {
        console.log({ FAILED_FETCH_PELATIHAN_PAGE: err })
        return null
      }),
  ]).then((res) => ({
    ...res[0],
  }))

  // console.log(formatted)

  return formatted
}

export const getContentForPelatihan = async (preview) => {
  let formatted = await Promise.all([
    wpFetcher({
      method: "GET",
      url: "/custom-page/181",
    })
      .then((res) => res?.data)
      .then((data) => ({
        title: data?.title?.rendered ?? null,
        body: data?.acf?.body_pelatihan ?? null,
        footer: data?.acf?.footer_pelatihan ?? null,
        url: data?.acf?.registration_url_pelatihan ?? null,
        cover: data?.acf?.cover_pelatihan ?? null,
        subtitle: data?.acf?.subtitle_pelatihan ?? null,

        partner_subtitle: data?.acf?.subtitle_pelatihan_partner ?? null,
        partner_name: data?.acf?.partner_pelatihan_name ?? null,
        partner_logo: data?.acf?.logo ?? null,
        partner_about: data?.acf?.about_partner ?? null,
        partner_url: data?.acf?.pelatihan_url_partner ?? null,
      }))
      .catch((err) => {
        console.log({ FAILED_FETCH_PELATIHAN_PAGE: err })
        return null
      }),

    wpFetcher({
      method: "GET",
      url: "/program",
    })
      .then((res) => res?.data)
      .then((data) => {
        return data
          ?.sort((a, b) => b?.id - a?.id)
          .map((d) => ({
            title: d?.title?.rendered ?? null,
            day: d?.acf?.program_day ?? null,
            hour: d?.acf?.program_hour ?? null,
            materi: d?.acf?.program_materi ?? null,
          }))
      })
      .catch((err) => {
        console.log({ FAILED_FETCH_PROGRAM: err })
        return null
      }),
  ]).then((res) => ({
    ...res[0],
    programs: res[1],
  }))

  return formatted
}

export const getContentForVisaSsw = async () => {
  try {
    let res = await wpFetcher({
      method: "GET",
      url: "/pages/14",
    })

    return res?.data
  } catch (error) {
    console.log({ FAILED_FETCH_PAGE: error })
    return null
  }
}

export const getContentForJadwalUjian = async () => {
  let data = await Promise.all([
    wpFetcher({
      method: "GET",
      url: "/jadwal?per_page=100",
    })
      .then((res) => {
        return res?.data
      })
      .then((response) =>
        response?.map((data) => {
          return {
            id: data?.id ?? null,
            title: data?.title?.rendered ?? null,
            place: data?.acf?.place ?? null,
            url: data?.acf?.url ?? null,
            date: data?.acf?.date ?? null,
            industry: data?.acf?.industry ?? null,
            occupation: data?.acf?.occupation ?? null,
          }
        })
      )
      .catch((err) => {
        console.log({ FAILED_FETCH_JADWAL: err?.response?.data })
        return null
      }),

    wpFetcher({
      method: "GET",
      url: "/custom-page/225",
    })
      .then((res) => {
        let acf = res?.data?.acf
        let title = res?.data?.title?.rendered
        let cover = res?.data?.acf?.cover

        let url = Object.keys(acf).filter(
          (k) => k.slice(0, 6) !== "label_" && k !== "cover"
        )

        let parsed = url?.map((l) => ({
          slug: l,
          label: acf[`label_${l}`],
          url: acf[l],
        }))

        return { parsed, title, cover }
      })
      .catch((err) => {
        console.log({ FAILED_FETCH_JADWAL: err })
        return null
      }),
  ]).then((res) => ({
    cover: res[1]?.cover,
    title: res[1]?.title,
    jadwal: res[0],
    url: res[1]?.parsed,
  }))

  // data?.jadwal?.forEach((d) => {
  //   d?.industry?.value === "kelistrikan" ? console.log(d) : null
  // })

  let formatted = groupBy(data.jadwal, "industry", "value")

  return { formatted, data: data?.url, title: data?.title, cover: data?.cover }
}

export const getContentComingSoonJobPage = async () => {
  try {
    const data = await wpFetcher({
      method: "GET",
      url: "/custom-page/264",
    }).then((res) => res?.data?.acf)

    if (data) {
      return {
        title: data?.job_title,
        subtitle: data?.job_subtitle,
        label: data?.job_cta_label,
        subtitle_registered: data?.job_subtitle_registered,
        label_registered: data?.job_cta_label_registered,
      }
    }
  } catch (error) {
    console.log({ FAILED_FETCH_JOB: error })
    return null
  }
}

export const getContentDashboard = async ({ signal }) => {
  try {
    const data = await wpFetcher({
      method: "GET",
      url: "/custom-page/349",
      signal,
    }).then((res) => res?.data?.acf)

    return data?.message_pelatihan
  } catch (error) {
    console.log({ failed_fetch_dashboard: error })
    return null
  }
}

export const fetchSchedulesContent = async ({ signal }) => {
  try {
    const res = await wpFetcher({
      method: "GET",
      url: `/jadwal`,
      signal,
      params: {
        per_page: 100,
      },
    })
    if (res?.status === 200) {
      return res?.data
    }
  } catch (error) {
    console.log({ failed_schedule: error })
  }
}
